import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Icon,
  Select,
  Stack,
} from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { allAccounts } from "../../Api/ApiQuery";
import MaterialDataGrid from "../../components/MaterialDataGrid";
export const ManualList = () => {
  const [pageState, setPageState] = useState({
    loading: false,
    error: false,
    message: "",
    state: "init",
  });

  const navigate = useNavigate();
  const handleNewEmployee = () => {
    navigate("/manual/Add-manual");
  };
  const [data, setData] = useState([]);
  const getlist = () => {
    const res = request(BASEURL, allAccounts)
      .then((res) => {
        console.log(res);
        setData(res?.allAccounts);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getlist();
  }, []);
  const staffListTable = {
    columns: [
      {
        field: "voucherId",
        headerName: "Voucher Id",
        // flex: 1,
        flex: 1,
        disableColumnMenu: true,
        // editable: false,
        sortable: true,
      },
      {
        field: "totalDebit",
        headerName: "Total Debit",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: true,
      },
      {
        field: "totalCredit",
        headerName: "Total Credit",
        // flex: 1,
        flex: 1,
        editable: false,
        disableColumnMenu: true,
        sortable: true,
      },
      {
        field: "voucherDate",
        headerName: "Voucher Date",
        // flex: 1,
        flex: 1,
        disableColumnMenu: true,
        editable: false,
        sortable: true,
      },
      // {
      //   field: "action",
      //   headerName: "Action",
      //   // flex: 1,
      //   flex: 1,
      //   disableColumnMenu: true,
      //   editable: false,
      //   sortable: true,
      // },
    ],
    row: data.map((staff, ind) => {
      return {
        id: staff?.voucherId,
        voucherId: staff.voucherId,
        totalDebit: staff.totalDebit,
        totalCredit: staff.totalCredit,
        voucherDate: staff.voucherDate,
        action:(
          <RemoveRedEyeIcon
          sx={{
            transform: "scale(1.5)",
            // marginRight: "35px",
            cursor: "pointer",
            color: "gray",
            marginLeft:'10px'
          }}
          // onClick={()=>handleNewEmployeeEdit(val?.id)}
        />
        )
      };
    }),
  };

  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <>
              {/* {togglePage.view === true || togglePage.edit === true ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack direction="column" spacing={0}>
                    <MaterialTypography variant="h5" fontWeight="medium">
                      Invoice Add
                    </MaterialTypography>
                  </Stack>
                </Stack>
              ) : ( */}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column" spacing={0}>
                  <MaterialTypography variant="h5" fontWeight="medium">
                    Manual List
                  </MaterialTypography>
                </Stack>
                <MaterialButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => handleNewEmployee()}
                >
                  Add Manual
                </MaterialButton>
              </Stack>
            </>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            // customHeight={550}
            rows={staffListTable.row}
            columns={staffListTable.columns}
            checkboxSelection={false}
            isSelectable={true}
            // onRowClick={onStaffListClick}
            loading={pageState.loading}
            localeText={{
              noRowsLabel: (
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <MaterialTypography
                    variant="body2"
                    fontWeight="medium"
                    color="text"
                  >
                    No staff found
                  </MaterialTypography>
                  <MaterialTypography variant="caption" color="text">
                    You have not yet add any staff details. Click on the "Add
                    staff" button to start adding staff details.
                  </MaterialTypography>
                </Stack>
              ),
            }}
          />
        </CardContent>

        {/* )} */}
      </Card>
    </DashboardLayout>
  );
};
