import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Icon,
  Select,
  Stack,
} from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { BsFillPersonVcardFill } from "react-icons/bs";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialSelect from "../../components/MasterSelect";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { filteredLedger } from "../../Api/ApiQuery";
import "../../assest/style/style.css";
import { createaccounts } from "../../Api/apiMutations";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";

const ManualAdd = () => {
  const navigate = useNavigate();
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const [dateStatus, setDateStatus] = useState("success");
  const [data, setdata] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [billing, setBilling] = useState([
    {
      ledgerName: {},
      credit: "",
      debit: "",
      creditStatus: "",
      debitStatus: "",
      ledgerNameStatus: "unckecked",
    },
    {
      ledgerName: {},
      credit: "",
      debit: "",
      creditStatus: "",
      debitStatus: "",
      ledgerNameStatus: "unckecked",
    },
  ]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    showConfirm: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
  });

  // const handeltabel = (e, ind, type) => {
  //   console.log(e, ind);

  //   const temp = [...billing];
  //   if (type == "select") {
  //     temp[ind] = {
  //       ...temp[ind],
  //       ledgerName: e,
  //       ledgerNameStatus: "success",
  //     };
  //   } else {
  //     if (e.target.name == "debit") {
  //       temp[ind] = {
  //         ...temp[ind],
  //         debit: e?.target?.value,
  //         creditStatus: "disabled",
  //         credit: "0",
  //         // [e?.target?.name + `Status`]: "success",
  //       };
  //     } else {
  //       temp[ind] = {
  //         ...temp[ind],
  //         debit: "0",
  //         credit: e?.target?.value,
  //         debitStatus: "disabled",
  //         // [e?.target?.name + `Status`]: "success",
  //       };
  //     }
  //   }

  //   setBilling(temp);
  // };
  const handeltabel = (e, ind, type) => {
    console.log(e, ind, type);

    const temp = [...billing];

    if (type === "select") {
      temp[ind] = {
        ...temp[ind],
        ledgerName: e,
        ledgerNameStatus: "success",
      };
    } else if (e.target.name === "debit") {
      temp[ind] = {
        ...temp[ind],
        debit: e.target.value,
        credit: "", // Reset credit for the same row
        creditStatus: "disabled", // Disable credit for the same row
      };
      // Disable debit in the alternate row
      if (ind % 2 === 0) {
        temp[ind + 1].debitStatus = "disabled";
        temp[ind + 1].debit = ""; // Reset debit in the alternate row
      } else {
        temp[ind - 1].debitStatus = "disabled";
        temp[ind - 1].debit = ""; // Reset debit in the alternate row
      }
      // Re-enable credit in the alternate row
      if (ind % 2 === 0) temp[ind + 1].creditStatus = "";
      else temp[ind - 1].creditStatus = "";
    } else if (e.target.name === "credit") {
      temp[ind] = {
        ...temp[ind],
        credit: e.target.value,
        debit: "", // Reset debit for the same row
        debitStatus: "disabled", // Disable debit for the same row
      };
      // Disable credit in the alternate row
      if (ind % 2 === 0) {
        temp[ind + 1].creditStatus = "disabled";
        temp[ind + 1].credit = ""; // Reset credit in the alternate row
      } else {
        temp[ind - 1].creditStatus = "disabled";
        temp[ind - 1].credit = ""; // Reset credit in the alternate row
      }
      // Re-enable debit in the alternate row
      if (ind % 2 === 0) temp[ind + 1].debitStatus = "";
      else temp[ind - 1].debitStatus = "";
    }

    setBilling(temp);
  };

  const addFunction = () => {
    const temp = [...billing];
    temp.push(
      {
        ledgerName: {},
        credit: "",
        debit: "",
        creditStatus: "",
        debitStatus: "",
        ledgerNameStatus: "unckecked",
      },
      {
        ledgerName: {},
        credit: "",
        debit: "",
        creditStatus: "",
        debitStatus: "",
        ledgerNameStatus: "unckecked",
      }
    );
    setBilling(temp);
  };
  const deleteFunction = (ind) => {
    console.log(ind);
    const temp = [...billing];
    temp.splice(ind, 2);
    console.log(temp);
    setBilling(temp);
  };
  const filterledgerfun = () => {
    const response = request(BASEURL, filteredLedger)
      .then((res) => {
        // console.log(res);

        const data = res?.filteredLedger.filter((item) =>
          billing?.every((itm) => itm?.ledgerName?.value !== item?.id)
        );
        console.log(data);

        setdata(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ondatechange = (e) => {
    console.log(e);

    if (e.target.value) {
      setDate(e.target.value);
      setDateStatus("success");
    } else {
      setDate(today);
      setDateStatus("success");
      // setDateStatus("error");
    }
  };
  useEffect(() => {
    filterledgerfun();
  }, [billing]);
  const creditadd = billing.reduce((total, num) => total + +num?.credit, 0);
  const debitadd = billing.reduce((total, num) => total + +num?.debit, 0);
  console.log(billing);
  const disabledHandel = () => {
    let disabled = true;
    // const add = (obj) => obj?.ledgerNameStatus === "success";
    // console.log(billing.every((obj) => obj?.ledgerNameStatus === "success"), "add");
    const ledgerstatus = billing.every(
      (obj) => obj?.ledgerNameStatus == "success"
    );
    console.log(ledgerstatus);

    if (creditadd == debitadd && ledgerstatus && dateStatus == "success") {
      disabled = false;
    } else {
      disabled = true;
    }
    return disabled;
  };

  const addmanual = () => {
    setConfirmationDialog({
      open: true,
      title: "Confirmation",
      message: "Are you sure do you want to submit your details.",
      showCancel: true,
      showConfirm: true,
      onConfirm: () => {
        const userdetails = localStorage.getItem("user");
        const userid = JSON.parse(userdetails);
        const value = billing.map((item) => ({
          user: userid?.id,
          ledger: item?.ledgerName?.value,
          debit: item?.debit ? item?.debit : "0",
          credit: item?.credit ? item?.credit : "0",
          voucherType: "Manual",
          voucherDate: date,
        }));
        const response = request(BASEURL, createaccounts, {
          input: value,
        })
          .then((res) => {
            console.log(res);
            navigate("/manual");
            setConfirmationDialog({
              open: true,
              title: "Success message",
              message: "Successfully Ledger Added",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          })
          .catch((err) => {
            console.log(err);
            setConfirmationDialog({
              open: true,
              title: "Error message",
              message:
                "We are unable to fetch the required data at this time. Please try again later. If the problem persists, please contact support.",
              showCancel: true,
              showConfirm: false,
              onCancel: () => {
                setConfirmationDialog({
                  ...confirmationDialog,
                  open: false,
                });
              },
            });
          });
      },
      onCancel: () => {
        setConfirmationDialog({
          ...confirmationDialog,
          open: false,
        });
      },
    });
  };

  const oneWeekBefore = new Date();
  const toDate = new Date();
  oneWeekBefore.setDate(toDate.getDate() - 7);
  console.log(date);
  // const exceptThisSymbols = ["e", "E", "+", "-", ".","A","a"];
  const exceptThisSymbols = [];

  for (let i = 65; i <= 90; i++) {
    exceptThisSymbols.push(String.fromCharCode(i));
  }

  for (let i = 97; i <= 122; i++) {
    exceptThisSymbols.push(String.fromCharCode(i));
  }

  const specialCharacters = `~!@#$%^&*()_+=[]{}|;:'",<>?/\\\`-`.split("");
  exceptThisSymbols.push(...specialCharacters);

  exceptThisSymbols.push("e", "E", "+", "-");

  console.log(exceptThisSymbols);

  return (
    <DashboardLayout>
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Add Manual
                </MaterialTypography>
              </Stack>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "90vh" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Stack direction="row" spacing={1}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <MaterialTypography
                  component="label"
                  variant="body2"
                  fontWeight="bold"
                >
                  Date:<span style={{ color: "red" }}>*</span>
                </MaterialTypography>
                <MaterialInput
                  value={date}
                  onChange={ondatechange}
                  type="date"
                  inputProps={{
                    min: oneWeekBefore.toISOString().split("T")[0],
                    max: new Date().toISOString().split("T")[0],
                  }}
                  sx={{ marginLeft: "5px" }}
                  error={dateStatus === "error" ? true : false}
                  success={dateStatus === "success" ? true : false}
                />
              </div>
            </Stack>
            <Stack direction="row" spacing={1}>
              <MaterialButton
                sx={{ m: 2 }}
                variant="gradient"
                color="dark"
                Icon={<AddIcon />}
                onClick={() => {
                  addFunction();
                }}
              >
                Add Manual
              </MaterialButton>
            </Stack>
          </div>
          <MaterialBox>
            <div className="tabel-target">
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "none",
                }}
              >
                <thead>
                  <tr>
                    <th>Ledger</th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {billing.map((item, ind) => {
                    return (
                      <tr>
                        <td>
                         
                          <MaterialSelect
                            className="onclick"
                            name="ledgerName"
                            onChange={(e) => {
                              handeltabel(e, ind, "select");
                            }}
                            value={item?.ledgerName}
                            customStyles={{ optionPadding: 20, maxHeight: 0 }}
                            options={data?.map((value) => {
                              return {
                                value: value?.id,
                                label: value?.ledgerName,
                              };
                            })}
                            
                            error={
                              item?.ledgerNameStatus === "error" ? true : false
                            }
                            success={
                              item?.ledgerNameStatus === "success"
                                ? true
                                : false
                            }
                          />
                        </td>

                        <td>
                          <MaterialInput
                            name="debit"
                            type="number"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={
                              item?.debit == "disabled" ? "0" : item?.debit
                            }
                            disabled={
                              item?.debitStatus == "disabled" ? true : false
                            }
                          ></MaterialInput>
                        </td>
                        <td>
                          <MaterialInput
                            name="credit"
                            type="number"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={
                              item?.credit == "disabled" ? "0" : item?.credit
                            }
                            disabled={
                              item?.creditStatus == "disabled" ? true : false
                            }
                          ></MaterialInput>
                        </td>
                        <td>
                          {ind % 2 == 0 && (
                            <DeleteIcon
                              sx={{
                                transform: "scale(1.5)",
                                marginLeft: "10px",
                                cursor: "pointer",
                                color: "red",
                              }}
                              onClick={() => {
                                deleteFunction(ind);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <th
                    style={{ textAlign: "center", fontWeight: "bolder" }}
                  ></th>

                  <th style={{ textAlign: "center", fontWeight: "bolder" }}>
                    Total Debit:
                    <span className="onclick">{`₹${debitadd}`}</span>
                  </th>
                  <th style={{ textAlign: "center", fontWeight: "bolder" }}>
                    Total Credit:
                    <span className="onclick">{`₹${creditadd}`}</span>
                  </th>
                  <th style={{ marginLeft: "10px" }}>
                    <MaterialButton
                      sx={{ m: 2 }}
                      variant="gradient"
                      color="dark"
                      disabled={disabledHandel()}
                      onClick={addmanual}
                    >
                      Submit
                    </MaterialButton>
                  </th>
                </tbody> */}
                <tbody>
                  {billing.map((item, ind) => {
                    if (ind % 2 === 0) {
                      return (
                        <>
                          <tr key={`row-${ind}`}>
                            <td>
                              <MaterialSelect
                                name="ledgerName"
                                onChange={(e) => handeltabel(e, ind, "select")}
                                value={item?.ledgerName}
                                defaultValue={{ label: "Please select" }}
                                options={data.map((value) => ({
                                  value: value.id,
                                  label: value.ledgerName,
                                }))}
                                error={
                                  item?.ledgerNameStatus === "error"
                                    ? true
                                    : false
                                }
                                success={
                                  item?.ledgerNameStatus === "success"
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td>
                              <MaterialInput
                                name="debit"
                                type="text"
                                onChange={(e) => handeltabel(e, ind)}
                                value={item?.debit || ""}
                                disabled={item?.debitStatus === "disabled"}
                                inputProps={{
                                  maxlength: "8",
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder="0"
                              />
                            </td>
                            <td>
                              <MaterialInput
                                name="credit"
                                type="text"
                                onChange={(e) => handeltabel(e, ind)}
                                value={item?.credit || ""}
                                disabled={item?.creditStatus === "disabled"}
                                inputProps={{
                                  maxlength: "8",
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder="0"
                              />
                            </td>
                            <td
                              rowSpan="2"
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center",
                              }}
                            >
                              <DeleteIcon
                                sx={{
                                  transform: "scale(1.5)",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                onClick={() => deleteFunction(ind)}
                              />
                            </td>
                          </tr>
                          <tr key={`row-${ind + 1}`}>
                            <td>
                              <MaterialSelect
                                name="ledgerName"
                                onChange={(e) =>
                                  handeltabel(e, ind + 1, "select")
                                }
                                defaultValue={{ label: "Please select" }}
                                value={billing[ind + 1]?.ledgerName}
                                options={data.map((value) => ({
                                  value: value.id,
                                  label: value.ledgerName,
                                }))}
                                error={
                                  billing[ind + 1]?.ledgerNameStatus === "error"
                                    ? true
                                    : false
                                }
                                success={
                                  billing[ind + 1]?.ledgerNameStatus ===
                                  "success"
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td>
                              <MaterialInput
                                name="debit"
                                type="text"
                                onChange={(e) => handeltabel(e, ind + 1)}
                                value={billing[ind + 1]?.debit || ""}
                                disabled={
                                  billing[ind + 1]?.debitStatus === "disabled"
                                }
                                inputProps={{
                                  maxlength: "8",
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder="0"
                              />
                            </td>
                            <td>
                              <MaterialInput
                                name="credit"
                                type="text"
                                onChange={(e) => handeltabel(e, ind + 1)}
                                value={billing[ind + 1]?.credit || ""}
                                disabled={
                                  billing[ind + 1]?.creditStatus === "disabled"
                                }
                                inputProps={{
                                  maxlength: "8",
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                placeholder="0"
                              />
                            </td>
                          </tr>
                          <th
                            style={{
                              textAlign: "center",
                              fontWeight: "bolder",
                            }}
                          ></th>
                        </>
                      );
                    }
                    return null;
                  })}
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "bolder",
                    }}
                  >
                    Total Debit:
                    <span className="onclick">{`₹${debitadd}`}</span>
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      fontWeight: "bolder",
                    }}
                  >
                    Total Credit:
                    <span className="onclick">{`₹${creditadd}`}</span>
                  </th>
                  <th style={{ marginLeft: "10px" }}>
                    <MaterialButton
                      sx={{ m: 2 }}
                      variant="gradient"
                      color="dark"
                      disabled={disabledHandel()}
                      onClick={addmanual}
                    >
                      Submit
                    </MaterialButton>
                  </th>
                </tbody>

                {/* <tbody>{renderRows(data)}</tbody> */}
              </table>
            </div>
          </MaterialBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default ManualAdd;
