import request, { gql } from "graphql-request";

export const chartList = gql`
  query {
    getParentLedger {
      id
      ledgerName
      ledgerId
      component
    }
  }
`;

export const viewparents = gql`
  query getledgerid($id: String!) {
    getLedgerById(id: $id) {
      ledgerName
      ledgerId
      component
    }
  }
`;

export const allSalesOrder = gql`
  query {
    allSalesOrder {
      id
      salesOrderId
      invoiceSet{
        id
      }
    }
  }
`;
export const salesorder = gql`
  query salesorder($id: String!) {
    getSalesOrderId(id: $id) {
      id
      salesOrderId
      customer {
        legalName
        address1Line1
        address1Line2
        address2Line1
        address2Line2
        country1
        country2
        city1
        city2
        state1
        state2
        zipCode1
        zipCode2
      }
    quotation{
      dueDate
    }
      billingAddress
      shippingAddress
      contactPerson
      totalTaxAmount
      amountAndTax
      totalAmount
      subTotal
      salesorderproductsSet {
        productName {
          productName
        }
        id
        SKU
        HSNNumber
        comments
        productrate
        quantity
        tax
        totalproductamount
        taxAmount
      }
      otherchargeSet {
        description
        amount
      }
      discountSet {
        discountPercentage
        amount
      }
    }
  }
`;

export const allVendor = gql`
  query {
    allVendor {
      id
      legalName
    }
  }
`;

export const allCustomer = gql`
  query {
    allCustomer {
      id
      legalName
    }
  }
`;

export const allUsersDetail = gql`
  query {
    allUsersDetail {
      id
      username
    }
  }
`;
export const allInvoice = gql`
  query {
    allInvoice {
      id
      invoiceId
      customer
      invoiceDate
      totalAmount
      invoiceproductSet {
        productId {
          productName {
            productName
          }
          quantity
          rate
          amount
          tax
          taxAmount
          totalproductamount
        }
      }
    }
  }
`;
export const GetLedgerHierarchy = gql`
  query GetLedgerHierarchy {
    ledgerHierarchy {
      id
      ledgerId
      ledgerName
      component
      parents
      children {
        id
        ledgerId
        ledgerName
        component
        parents
        children {
          id
          ledgerId
          ledgerName
          component
          parents
          children {
            id
            ledgerId
            ledgerName
            component
            parents
            children {
              id
              ledgerId
              ledgerName
              component
              parents
              children {
                id
                ledgerId
                ledgerName
                component
                parents
                children {
                  id
                  ledgerId
                  ledgerName
                  component
                  parents
                  children {
                    id
                    ledgerId
                    ledgerName
                    component
                    parents
                    children {
                      id
                      ledgerId
                      ledgerName
                      component
                      parents
                      children {
                        id
                        ledgerId
                        ledgerName
                        component
                        parents
                        children {
                          id
                          ledgerId
                          ledgerName
                          component
                          parents
                          children {
                            id
                            ledgerId
                            ledgerName
                            component
                            parents
                            children {
                              id
                              ledgerId
                              ledgerName
                              component
                              parents
                              children {
                                id
                                ledgerId
                                ledgerName
                                component
                                parents
                                children {
                                  id
                                  ledgerId
                                  ledgerName
                                  component
                                  parents
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getledgerid = gql`
  query getledgerid($id: String!) {
    getLedgerById(id: $id) {
      ledgerName

      component
    }
  }
`;
export const filteredLedger = gql`
  query {
    filteredLedger {
      id
      ledgerName
    }
  }
`;
export const allAccounts = gql`
  query {
    allAccounts {
      voucherId
      totalDebit
      totalCredit
      voucherDate
    }
  }
`;

export const trailBalance = gql`
  query trailBalance($from: String!, $to: String!) {
    trailBalance(fromDate: $from, toDate: $to) {
      voucherId
      ledgerName
      debit
      credit
      voucherDate
    }
    totalSummary(fromDate: $from, toDate: $to) {
      totalDebitAll
      totalCreditAll
    }
  }
`;

export const invoicedetails = gql`
  query filterid($id: Int!) {
    invoice(id: $id) {
      dueDate
      totalAmount
    }
  }
`;

export const allinvoicenew = gql`
  query {
    allInvoice {
      id
      invoiceId
    }
  }
`;
export const viewInvoice = gql`
  query invoice($id: Int!) {
    invoice(id: $id) {
      id
      so {
        id
        salesOrderId
      }
      customer
      invoiceDate
      dueDate
      amount
      totalTaxAmount
      discountPercentage
      totalAmount
      termsAndConditions
      billingAddress
      billingCountry
      billingState
      billingCity
      billingZipline
      shippingAddress
      shippingCountry
      shippingState
      shippingCity
      shippingZipline
      subtotal
      invoiceproductSet {
        productId {
          productName {
            productName
            HSNNumber
            SKU
          }
          comments
          quantity
          rate
          tax
          taxAmount
          totalproductamount
        }
      }
      otherchargeSet {
        description
        amount
      }
    }
  }
`;

export const allReceiptVoucher = gql`
  query {
    allReceiptVoucher {
      receiptvoucherId
      against
      businessPartner
      receiptDate
      receiptAmount
    }
  }
`;
