import { SvgIcon } from "@mui/material";
import Dashboard from "./pages/dashboard";
import Employeeonboarding from "./pages/onboarding/employeeonboarding";
import EmployeeManagement from "./pages/employeemanagement";
import ManagerManagement from "./pages/managermanagement";
import Department from "./pages/department";
import Designation from "./pages/designation";
import Payroll from "./pages/payroll";
import LeaveManagement from "./pages/leavemanagement";
import RegularizationRequest from "./pages/regularizationrequest";
import WFHManagemnt from "./pages/wfhmanagement";
import Attendance from "./pages/attendance";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import BadgeIcon from "@mui/icons-material/Badge";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AddEmployee from "./pages/employeemanagement/components/AddEmployee";
import Signin from "./pages/authentication/signin";
import { Shop } from "@mui/icons-material";
import AddManager from "./pages/managermanagement/components/AddManager";
import Items from "./Accounts/Sales/Items";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

// import Vendorpurchase from "./Accounts/Accounts/vendorpurchase";
import AddBudjet from "./Accounts/Accounts/AddBudjet";
import ChartedAccounts from "./Accounts/Accounts/ChartedAccounts";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Coming from "./Accounts/Coming";

import Addcustomer from "./Accounts/Sales/AddCustomer";
import AddChartedAccounts from "./Accounts/Accounts/AddChartedAccounts";
import DashBoard from "./Accounts/DashBoard";
import Paymentrecive from "./Accounts/Sales/Paymentrecive";
import Vendor from "./Accounts/vendor";
import Purchasorders from "./Accounts/Purchasorders";
import Bills from "./Accounts/Bills";
import Paymentmode from "./Accounts/Paymentmode";
import AddBills from "./Accounts/AddBills";
import Vendors from "./Accounts/Vendors";
import AddSalesCreate from "./Accounts/Sales/AddSalesCreate";
import AddpurchasOrder from "./Accounts/AddpurchasOrder";
import AddSalesOrder from "./Accounts/Sales/AddSalesOrder";
import AddpaymentMade from "./Accounts/AddpaymentMade";
import AddVendorCretids from "./Accounts/AddVendorCretids";
import AddInvoice from "./Accounts/NewAccounts/AddInvoice";
import Payment from "./Accounts/NewAccounts/Payment";
import Reports from "./Accounts/NewAccounts/Reports";
import Receipt from "./Accounts/NewAccounts/Receipt";
import PaymentList from "./Accounts/NewAccounts/PaymentList";
import InvoiceList from "./Accounts/NewAccounts/InvoiceList";
import RecepitList from "./Accounts/NewAccounts/RecepitList";
import ReceiptAdd from "./Accounts/NewAccounts/Receipt";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PaymentIcon from "@mui/icons-material/Payment";
import ReportIcon from "@mui/icons-material/Report";
import ManualAdd from "./Accounts/NewAccounts/ManualAdd";
import { ManualList } from "./Accounts/NewAccounts/ManualList";
const routes = [
  {
    name: "Authentication",
    key: "authentication",
    collapse: [
      {
        name: "Sign in",
        key: "sign-in",
        route: "/sign-in",
        component: <Signin/>,
      },
    ],
  },
  {
    name: "Onboarding",
    key: "onboarding",
    collapse: [
      {
        name: "Employee Onboarding",
        key: "employee-onboarding",
        route: "/employeeonboarding",
        component: <Employeeonboarding />,
      },
    ],
  },
  {
    type: "collapse",
    icon: <HomeIcon />,
    name: "Home",
    key: "dashboard",
    route: "/dashboard",
    component: <DashBoard />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    icon: <AccountBoxIcon />,
    name: "Chart of Accounts",
    key: "Accounts",
    route: "/Accounts",
    component: <ChartedAccounts />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "collapse",
    icon: <NoteAltIcon />,
    name: "Invoice",
    key: "Invoice",
    route: "/Invoice",
    component: <InvoiceList />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "Invoice",
    key: "Add-Invoice",
    route: "/Invoice/Add-Invoice",
    component: <AddInvoice />,
    protected: true,
  },
  {
    type: "collapse",
    icon: <PaymentIcon />,
    name: "Manual Ledger",
    key: "manual",
    route: "/manual",
    component: <ManualList/>,
    noCollapse: true,
    protected: true,
  },
  {
    type: "Payment",
    key: "Add-manual",
    route: "/manual/Add-manual",
    component: <ManualAdd/>,
    protected: true,
  },
  {
    type: "collapse",
    icon: <PaymentIcon />,
    name: "Payment Voucher",
    key: "payment",
    route: "/payment",
    component: <PaymentList />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "Payment",
    key: "Add-Payment",
    route: "/Payment/Add-Payment",
    component: <Payment />,
    protected: true,
  },
  {
    type: "collapse",
    icon: <ReceiptIcon />,
    name: "Receipt Voucher",
    key: "Receipt",
    route: "/Receipt",
    component: <RecepitList />,
    noCollapse: true,
    protected: true,
  },
  {
    type: "Receipt",
    key: "Add-Receipt",
    route: "/Receipt/Add-Receipt",
    component: <ReceiptAdd />,
    protected: true,
  },
  {
    type: "collapse",
    icon: <ReportIcon />,
    name: "Report",
    key: "Report",
    route: "/Report",
    component: <Reports />,
    noCollapse: true,
    protected: true,
  },

  //demo
  // {
  //   type: "AddChartedAccounts",
  //   key: "Add-Chart-of-Accounts",
  //   route: "/Chart-of-Accounts/Add-Chart-of-Accounts",
  //   component: <AddChartedAccounts />,
  //   protected: true,
  // },
  // {
  //   type: "AddSalesOrder",
  //   key: "AddSalesOrder",
  //   route: "/Sales/sales-order/AddSalesOrder",
  //   component: <AddSalesOrder />,
  //   protected: true,
  // },
  // {
  //   type: "Payment-made",
  //   key: "Add-Payment",
  //   route: "/Purchases/Add-Payment",
  //   component: <AddpaymentMade />,
  //   protected: true,
  // },
  // {
  //   type: "Add-Vendor-Credits",
  //   key: "Add-Vendor-Credits",
  //   route: "/Purchases/Vendor-Credits/Add-Vendor-Credits",
  //   component: <AddVendorCretids />,
  //   protected: true,
  // },
  // {
  //   type: "AddChartedAccounts",
  //   key: "Addcredit",
  //   route: "/Sales/Credit/AddCredit",
  //   component: <AddSalesCreate />,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   icon: <NoteAltIcon />,
  //   name: "Budgets",
  //   key: "Budgets",
  //   route: "/Budgets",
  //   component: <Budgets />,
  //   noCollapse: true,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   icon: <ContactsIcon />,
  //   name: "Manual Journals",
  //   key: "Manual-Journals",
  //   route: "/Manual-Journals",
  //   component: <ManualAccount />,
  //   noCollapse: true,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   icon: <LocalAtmIcon />,
  //   name: "e-Way Bills",
  //   key: "e-way-Bills",
  //   route: "/e-way-Bills",
  //   component: <Coming />,
  //   noCollapse: true,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   icon: <ManageAccountsIcon />,
  //   name: "GST Filling",
  //   key: "gst-filling",
  //   route: "/gst-filling",
  //   component: <Coming />,
  //   noCollapse: true,
  //   protected: true,
  // },

  // {
  //   type: "collapse",
  //   name: "Sales",
  //   key: "Sales",
  //   icon: <ShoppingCartIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Items",
  //       key: "default",
  //       route: "/Items/default",
  //       component: <Items />,
  //     },
  //     {
  //       name: "Customers",
  //       key: "Customers",
  //       route: "/Sales/Customers",
  //       component: <SalesCustomer />,
  //     },
  //     // {
  //     //   name: "Estimates",
  //     //   key: "Estimates",
  //     //   route: "/Sales/Estimates",
  //     //   component: <SalesEstimates />,
  //     // },
  //     // {
  //     //   name: "Retainer Invoices",
  //     //   key: "Retainer-Invoices",
  //     //   route: "/Sales/Retainer-Invoices",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     {
  //       name: "Sales Order",
  //       key: "sales-order",
  //       route: "/Sales/sales-order",
  //       component: <SalesOrder />,
  //     },
  //     // {
  //     //   name: "Delivery Challans",
  //     //   key: "Delivery",
  //     //   route: "/Sales/Delivery",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     {
  //       name: "Invoices",
  //       key: "Invoices",
  //       route: "/Sales/Invoices",
  //       component: <SalesInvoices />,
  //     },
  //     // {
  //     //   name: "Payment Links",
  //     //   key: "Payment",
  //     //   route: "/Sales/Payment",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     {
  //       name: "Payment Recevied",
  //       key: "Recevied",
  //       route: "/Sales/Recevied",
  //       component: <SalesPayments />,
  //     },
  //     // {
  //     //   name: "Recurring Invoices",
  //     //   key: "Recurring",
  //     //   route: "/Sales/Recurring",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     {
  //       name: "Credit Note`s",
  //       key: "Credit",
  //       route: "/Sales/Credit",
  //       component: <SalesCreate />,
  //     },
  //   ],
  // },
  // {
  //   type: "Customer",
  //   key: "payment",
  //   route: "/Sales/payment",
  //   component: <Paymentrecive />,
  //   protected: true,
  // },
  // {
  //   type: "Customer",
  //   key: "customer",
  //   route: "/Sales/customer",
  //   component: <Addcustomer />,
  //   protected: true,
  // },
  // {
  //   type: "AddEmployee",
  //   key: "add-invoices",
  //   route: "/Sales/add-invoices",
  //   component: <AddInvoices />,
  //   protected: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Purchases",
  //   key: "Purchases",
  //   icon: <BusinessCenterIcon size="12px" />,
  //   collapse: [
  //     {
  //       name: "Vendors",
  //       key: "Vendors",
  //       route: "/Purchases/Vendors",
  //       component: <Vendor />,
  //     },
  //     // {
  //     //   name: "Expenses",
  //     //   key: "Expenses",
  //     //   route: "/Purchases/Expenses",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     // {
  //     //   name: "Recurring Expenses",
  //     //   key: "Recurring-Expenses",
  //     //   route: "/Purchases/Recurring-Expenses",
  //     //   component: <EmployeeManagement />,
  //     // },
  //     {
  //       name: "Purchase Orders",
  //       key: "Purchase-Orders",
  //       route: "/Purchases/Purchase-Orders",
  //       component: <Purchasorders />,
  //     },
  //     {
  //       name: "Bills",
  //       key: "Bills",
  //       route: "/Purchases/Bills",
  //       component: <Bills />,
  //     },
  //     {
  //       name: "Payments Mode",
  //       key: "Payment-mode",
  //       route: "/Purchases/Payment-mode",
  //       component: <Paymentmode />,
  //     },
  // {
  //   name: "Recurring Bills",
  //   key: "Recurring-Bills",
  //   route: "/Purchases/Recurring-Bills",
  //   component: <EmployeeManagement />,
  // },
  //       {
  //         name: "Vendor Credits",
  //         key: "Vendor-Credits",
  //         route: "/Purchases/Vendor-Credits",
  //         component: <Vendorcredits />,
  //       },
  //     ],
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-Bills",
  //     route: "/Purchases/add-Bills",
  //     component: <AddBills />,
  //     protected: true,
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-Purchase",
  //     route: "/Purchases/add-Purchase",
  //     component: <AddpurchasOrder />,
  //     protected: true,
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-Vendors",
  //     route: "/Purchases/add-Vendors",
  //     component: <Vendors />,
  //     protected: true,
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-Bills",
  //     route: "/Purchases/add-Bills",
  //     component: <AddBills />,
  //     protected: true,
  //   },

  //   {
  //     type: "AddEmployee",
  //     key: "add-employee",
  //     route: "/employee-management/add-employee",
  //     component: <AddEmployee />,
  //     protected: true,
  //   },
  //   {
  //     type: "EmployeeDetails",
  //     key: "employee-details",
  //     route: "/employee-management/employee-details",
  //     component: <AddEmployee />,
  //     protected: true,
  //   },

  //   {
  //     type: "AddEmployee",
  //     key: "add-Journal",
  //     route: "/Accountant/add-Journal",
  //     component: <AddManulAccount />,
  //     protected: true,
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-manule",
  //     route: "/Accountant/addbudgets",
  //     component: <AddBudjet />,
  //     protected: true,
  //   },
  //   {
  //     type: "AddEmployee",
  //     key: "add-Accounts",
  //     route: "/add-Accounts",
  //     component: <AddEmployee />,
  //     protected: true,
  //   },

  //   {
  //     type: "collapse",
  //     icon: <ManageAccountsIcon />,
  //     name: "Reports",
  //     key: "reports",
  //     route: "/reports",
  //     component: <Reports />,
  //     noCollapse: true,
  //     protected: true,
  //   },
];
export default routes;
