import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { allReceiptVoucher } from "../../Api/ApiQuery";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
const RecepitList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const appointmentListTable = {
    columns: [
      {
        headerName: "Receipt Voucher Id",
        field: "receiptvoucherId",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Against",
        field: "against",

        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      // {
      //   headerName: "Business Partner",
      //   field: "businessPartner",

      //   flex: 1,
      //   disableColumnMenu: true,
      //   editable: false,
      // },
      {
        headerName: "Receipt Amount",
        field: "receiptAmount",
        name: "Name",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Receipt Date",
        field: "receiptDate",

        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },

      // {
      //   headerName: "Action",
      //   field: "action", // This is the field name you defined in your table columns
      //   name: "Created By",
      //   flex: 1,
      //   disableColumnMenu: true,
      //   editable: false,
      // },
    ],
    rows: data.map((val, index) => ({
      id: val.receiptvoucherId,
      against: val.against,
      businessPartner: val.businessPartner,
      receiptAmount: val.receiptAmount,
      receiptDate: val.receiptDate,
      receiptvoucherId: val.receiptvoucherId,
      action: (
        <RemoveRedEyeIcon
          sx={{
            transform: "scale(1.5)",
            // marginRight: "35px",
            cursor: "pointer",
            color: "gray",
            // marginLeft: "50px",
          }}
        />
      ),
    })),
  };
  const handleNewEmployee = () => {
    navigate("/Receipt/Add-Receipt");
  };

  const getList = () => {
    const res = request(BASEURL, allReceiptVoucher)
      .then((res) => {
        console.log(res);
        setData(res?.allReceiptVoucher);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getList();
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Receipt List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add Receipt
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default RecepitList;
