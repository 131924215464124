import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import {
  Box,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  FormHelperText,
  Icon,
  Select,
  Stack,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MaterialTypography from "../../components/MaterialTypography";
import { BsFillPersonVcardFill } from "react-icons/bs";
import MaterialBox from "../../components/MaterialBox";
import MaterialInput from "../../components/MaterialInput";
import MaterialSelect from "../../components/MasterSelect";
import "../../assest/style/style.css";
import MaterialButton from "../../components/MaterialButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Receipt } from "@mui/icons-material";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { allCustomer, allUsersDetail, allVendor } from "../../Api/ApiQuery";
const Payment = () => {
  const today = new Date().toISOString().split("T")[0];
  const [billing, setBilling] = useState([
    {
      type: {},
      bill_no: "",
      bill_date: "",
      bill_amount: "",
      outstanding: "",
      allocating: "",
      typeStatus: "unchecked",
      bill_noStatud: "unchecked",
    },
  ]);
  const [disabled, setDisabled] = useState(false);
  const [deduction, setDeduction] = useState([
    {
      account: "",
      cost: "",
      amount: "",

      accountStatus: "unchecked",
      cost: "unchecked",
    },
  ]);

  const [paymentdel, setPaymentdel] = useState({
    voucher_name: "",
    against: "",
    Receipt_date: today,
    businees_par: "",
    payment_mode: {
      label: "Accounts Transfer",
      value: "Accounts Transfer",
    },
    name_of_bank: "",
    payment_amount: "",
    cheque_num: "",
    cheque_date: "",
    payment_reference: "",
    remarks: "",

    voucher_nameStatus: "unchecked",
    againstStatus: "unchecked",
    Receipt_dateStatus: "unchecked",
    businees_parStatus: "unchecked",
    payment_modeStatus: "unchecked",
    name_of_bankStatus: "unchecked",
    payment_amountStatus: "unchecked",
    cheque_numStatus: "unchecked",
    cheque_dateStatus: "unchecked",
    payment_referenceStatus: "unchecked",
    remarksStatus: "unchecked",
  });

  const [vendor, setVendor] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [employe, setEmployee] = useState([]);

  const addFunction = () => {
    const temp = [...billing];
    temp.push({
      type: "",
      bill_no: "",
      bill_date: "",
      bill_amount: "",
      outstanding: "",
      allocating: "",
    });
    setBilling(temp);
  };
  const deleteFunction = (ind) => {
    console.log(ind);
    const temp = [...billing];
    temp.splice(ind, 1);
    console.log(temp);
    setBilling(temp);
  };

  const handeltabel = (e, selectEvent, ind) => {
    console.log(e, ind);

    const temp = [...billing];
    if (selectEvent) {
      temp[ind] = {
        ...temp[ind],
        selectEvent: e,
        [selectEvent + `Status`]: "success",
      };
    } else {
      temp[ind] = {
        ...temp[ind],
        [e.target.name]: e.target.value,
      };
    }

    setBilling(temp);
  };

  console.log(billing, "billing");

  const Against = [
    {
      value: "Payment to Vendor",
      label: "Payment to Vendor",
    },
    {
      value: "Refund to Customer",
      label: "Refund to Customer",
    },
    {
      value: "Payment to Employee",
      label: "Payment to Employee",
    },
  ];
  const payment = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Cheque",
      label: "Cheque",
    },
    {
      value: "Accounts Transfer",
      label: "Accounts Transfer",
    },
  ];
  // onchange handelor

  const handelOnchange = (event, selectEvent) => {
    let temp = { ...paymentdel };

    if (event?.target === undefined && selectEvent) {
      switch (selectEvent.name) {
        case "against": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "businees_par": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "payment_mode": {
          if (event) {
            temp[selectEvent?.name + "Status"] = "success";
          } else {
            temp[selectEvent?.name + "Status"] =
              selectEvent.value === "" ? "unchecked" : "error";
          }
          break;
        }
      }
      temp[selectEvent.name] = event;
    } else {
      switch (event?.target.name) {
        case "payment_amount": {
          if (/^[0-9]{1,7}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "payment_reference": {
          if (/^[a-zA-Z0-9\s]{1,25}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "cheque_num": {
          if (/^[0-9]{1,7}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "cheque_date": {
          if (event?.target.value) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
        case "remarks": {
          if (/^[a-zA-Z0-9\s]{1,250}$/.test(event?.target.value)) {
            temp[event?.target.name + "Status"] = "success";
          } else {
            temp[event?.target.name + "Status"] =
              event?.target?.value === "" ? "unchecked" : "error";
          }
          break;
        }
      }
      temp[event?.target.name] = event?.target.value;
    }
    setPaymentdel(temp);
  };

  console.log(paymentdel, "paymentdel");

  const getallvendor = () => {
    const response = request(BASEURL, allVendor)
      .then((res) => {
        setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getallCustomer = () => {
    const response = request(BASEURL, allCustomer)
      .then((res) => {
        // console.log(res);
        setCustomer(res?.allCustomer);
        // setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getallEmplu = () => {
    const response = request(BASEURL, allUsersDetail)
      .then((res) => {
        // console.log(res);
        setEmployee(res?.allUsersDetail);
        // setVendor(res?.allVendor);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getallvendor();
    getallCustomer();
    getallEmplu();
  }, []);

  const type = [
    {
      value: "Purchase Order",
      label: "Purchase Order",
    },
    {
      value: "Purchase Bill",
      label: "Purchase Bill",
    },
    {
      value: "Bills Outstanding",
      label: "Bills Outstanding",
    },
    {
      value: "Debit Note",
      label: "Debit Note",
    },
  ];

  const customerType = [
    {
      value: "Sales  Invoice",
      label: "Sales  Invoice",
    },
    {
      value: "Performa Invoice",
      label: "Performa Invoice",
    },
    {
      value: "Invoice Outstanding",
      label: "Invoice Outstanding",
    },
    {
      value: "Credit Note",
      label: "Credit Note",
    },
  ];

  const employeType = [
    {
      value: "Employees",
      label: "Employees",
    },
  ];
  const disabledHandel = () => {
    let disabledstatus = false;
    if (
      billing?.typeStatus == "success" &&
      billing?.bill_noStatud == "success" &&
      deduction?.accountStatus == "success" &&
      deduction?.cost == "success" &&
      paymentdel?.againstStatus == "success" &&
      paymentdel?.businees_parStatus == "success" &&
      paymentdel?.payment_modeStatus == "success" &&
      paymentdel?.payment_amountStatus == "success"
    ) {
      disabledstatus = false;
    } else {
      disabledstatus = true;
    }

    return disabledstatus;
  };

  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="column" spacing={0}>
                  <MaterialTypography variant="h5" fontWeight="medium">
                    Add Payment
                  </MaterialTypography>
                </Stack>
              </Stack>
            </>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Payment Details
            </MaterialTypography>
          </Stack>
          <MaterialBox>
            <Grid container spacing={2}>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Voucher Name <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="voucher_name"
                  placeholder="Enter voucher name"
                  value={paymentdel.voucher_name}
                  onChange={handelOnchange}
                  error={
                    paymentdel.voucher_nameStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.voucher_nameStatus === "success" ? true : false
                  }
                  // disabled={true}
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Against <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="against"
                  value={paymentdel.against}
                  defaultValue={{ label: "Please select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={Against?.map((value) => {
                    return {
                      value: value?.value,
                      label: value?.label,
                    };
                  })}
                  error={paymentdel?.againstStatus === "error" ? true : false}
                  success={
                    paymentdel?.againstStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                  isClearable
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Receipt Date <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  type="date"
                  name="Receipt_date"
                  placeholder="Enter voucher name"
                  value={paymentdel.Receipt_date}
                  onChange={handelOnchange}
                  error={
                    paymentdel.Receipt_dateStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.Receipt_dateStatus === "success" ? true : false
                  }
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Business Partner <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="businees_par"
                  value={paymentdel?.businees_par}
                  defaultValue={{ label: "Please Select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={
                    paymentdel?.against?.value == "Payment to Vendor"
                      ? vendor?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : paymentdel?.against?.value == "Refund to Customer"
                      ? customer?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : paymentdel?.against?.value == "Payment to Employee"
                      ? employe?.map((value) => {
                          return {
                            value: value?.id,
                            label: value?.legalName,
                          };
                        })
                      : []
                  }
                  error={
                    paymentdel?.businees_parStatus === "error" ? true : false
                  }
                  success={
                    paymentdel?.businees_parStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                  isClearable
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Mode <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="payment_mode"
                  value={paymentdel?.payment_mode}
                  defaultValue={{ label: "Please Select" }}
                  customStyles={{ optionPadding: 20 }}
                  onChange={handelOnchange}
                  options={payment?.map((value) => {
                    return {
                      value: value?.value,
                      label: value?.label,
                    };
                  })}
                  error={
                    paymentdel.payment_modeStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.payment_modeStatus === "success" ? true : false
                  }
                  // isDisabled={togglePage?.view === true ? true : false}
                  isClearable
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Name of the Bank/Cash{" "}
                    <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="name_of_bank"
                  placeholder="Enter Name of the Bank/Cash"
                  value={
                    paymentdel?.payment_mode?.value == "Cash"
                      ? "11200"
                      : "11300"
                  }
                  onChange={handelOnchange}
                  // onChange={handleleadandProspectData}
                  error={
                    paymentdel.name_of_bankStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.name_of_bankStatus === "success" ? true : false
                  }
                  disabled
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Amount <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="payment_amount"
                  type="number"
                  placeholder="Enter payment Amount"
                  value={paymentdel.payment_amount}
                  onChange={handelOnchange}
                  error={
                    paymentdel.payment_amountStatus === "error" ? true : false
                  }
                  success={
                    paymentdel.payment_amountStatus === "success" ? true : false
                  }
                />
              </Grid>
              {paymentdel?.payment_mode?.label == "Cheque" && (
                <>
                  <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                    <MaterialBox lineHeight={0} display="inline-block">
                      <MaterialTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Cheque Number <span style={{ color: "red" }}>*</span>
                      </MaterialTypography>
                    </MaterialBox>
                    <MaterialInput
                      name="cheque_num"
                      placeholder="Enter Cheque Number"
                      value={paymentdel.cheque_num}
                      onChange={handelOnchange}
                      error={
                        paymentdel.cheque_numStatus === "error" ? true : false
                      }
                      success={
                        paymentdel.cheque_numStatus === "success" ? true : false
                      }
                    />
                  </Grid>
                  <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                    <MaterialBox lineHeight={0} display="inline-block">
                      <MaterialTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Cheque Date <span style={{ color: "red" }}>*</span>
                      </MaterialTypography>
                    </MaterialBox>
                    <MaterialInput
                      type="date"
                      name="cheque_date"
                      placeholder="Enter voucher name"
                      value={paymentdel.cheque_date}
                      onChange={handelOnchange}
                      error={
                        paymentdel.cheque_dateStatus === "error" ? true : false
                      }
                      success={
                        paymentdel.cheque_dateStatus === "success"
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </>
              )}
              <Grid size={{ xs: 5, sm: 3, lg: 8 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Payment Reference
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="payment_reference"
                  placeholder="Enter Payment Reference"
                  value={paymentdel.payment_reference}
                  // onChange={handleleadandProspectData}
                  onChange={handelOnchange}
                  error={
                    paymentdel.payment_referenceStatus === "error"
                      ? true
                      : false
                  }
                  success={
                    paymentdel.payment_referenceStatus === "success"
                      ? true
                      : false
                  }
                />
              </Grid>
              <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Remarks
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="remarks"
                  placeholder="Enter Remarks"
                  value={paymentdel.remarks}
                  // onChange={handleleadandProspectData}
                  onChange={handelOnchange}
                  error={paymentdel.remarksStatus === "error" ? true : false}
                  success={
                    paymentdel.remarksStatus === "success" ? true : false
                  }
                />
              </Grid>
            </Grid>
          </MaterialBox>
          <Divider />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Stack direction="row" spacing={1}>
              <BsFillPersonVcardFill style={{ fontSize: 20 }} />
              <MaterialTypography
                component="label"
                variant="body2"
                fontWeight="bold"
              >
                Bill Details
              </MaterialTypography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <MaterialButton
                sx={{ m: 2 }}
                variant="gradient"
                color="dark"
                Icon={<AddIcon />}
                onClick={() => {
                  addFunction();
                }}
              >
                Add Billing
              </MaterialButton>
            </Stack>
          </div>

          <MaterialBox>
            <div className="tabel-target">
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "none",
                }}
              >
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Bill No</th>
                    <th>Bill Date</th>
                    <th>Bill Amount</th>
                    <th>Outstanding Amount</th>
                    <th>Allocating Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {billing.map((item, ind) => {
                    return (
                      <tr>
                        <td style={{ width: "230px" }}>
                          <select
                            name="type"
                            // defaultValue={v}
                            onChange={(e) => {
                              handeltabel(e, "type", ind);
                            }}
                            options={
                              paymentdel?.against?.value == "Payment to Vendor"
                                ? type?.map((value) => {
                                    return {
                                      value: value?.value,
                                      label: value?.label,
                                    };
                                  })
                                : paymentdel?.against?.value ==
                                  "Refund to Customer"
                                ? customerType?.map((value) => {
                                    return {
                                      value: value?.value,
                                      label: value?.label,
                                    };
                                  })
                                : paymentdel?.against?.value ==
                                  "Payment to Employee"
                                ? employeType?.map((value) => {
                                    return {
                                      value: value?.value,
                                      label: value?.label,
                                    };
                                  })
                                : []
                            }
                            value={item?.type}
                            error={item.typeStatus === "error" ? true : false}
                            success={
                              item?.typeStatus === "success" ? true : false
                            }
                          ></select>
                        </td>
                        <td style={{ width: "230px" }}>
                          <MaterialSelect
                            name="bill_no"
                            onChange={(e) => {
                              handeltabel(e, "bill_no", ind);
                            }}
                            value={item?.bill_no}
                          ></MaterialSelect>
                        </td>
                        <td style={{ width: "230px" }}>
                          <MaterialInput
                            name="bill_date"
                            type="date"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.bill_date}
                          ></MaterialInput>
                        </td>
                        <td style={{ width: "230px" }}>
                          <MaterialInput
                            name="bill_amount"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.bill_amount}
                          ></MaterialInput>
                        </td>
                        <td style={{ width: "230px" }}>
                          <MaterialInput
                            name="outstanding"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.outstanding}
                          ></MaterialInput>
                        </td>
                        <td
                           style={{ width: "30px" }}
                        >
                          <MaterialInput
                            name="allocating"
                            onChange={(e) => {
                              handeltabel(e, ind);
                            }}
                            value={item?.allocating}
                          ></MaterialInput>
                        </td>
                        <td>
                          <DeleteIcon
                            sx={{
                              transform: "scale(1.5)",
                              marginLeft: "10px",
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => {
                              deleteFunction(ind);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <tbody>{renderRows(data)}</tbody> */}
              </table>
            </div>
          </MaterialBox>
          <Divider />
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Deduction / Loss
            </MaterialTypography>
          </Stack>
          <MaterialBox>
            <div className="tabel-target">
              <table
                border="1"
                cellPadding="10"
                cellSpacing="0"
                style={{
                  width: "100%",
                  borderCollapse: "collapse",
                  border: "none",
                }}
              >
                <thead>
                  <tr>
                    <th>Account</th>
                    <th>Cost Center</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {deduction.map((item) => {
                    return (
                      <tr>
                        <td>
                          <MaterialSelect></MaterialSelect>
                        </td>
                        <td>
                          <MaterialSelect></MaterialSelect>
                        </td>
                        <td>
                          <MaterialInput></MaterialInput>
                        </td>
                        <td>
                          <AddIcon
                            sx={{
                              transform: "scale(1.5)",
                              // marginRight: "35px",
                              cursor: "pointer",
                              color: "green",
                            }}
                          />
                          <DeleteIcon
                            sx={{
                              transform: "scale(1.5)",
                              marginLeft: "35px",
                              cursor: "pointer",
                              color: "red",
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                {/* <tbody>{renderRows(data)}</tbody> */}
              </table>
            </div>
          </MaterialBox>
          <Divider />
          <Stack direction="row" spacing={1}>
            <BsFillPersonVcardFill style={{ fontSize: 20 }} />
            <MaterialTypography
              component="label"
              variant="body2"
              fontWeight="bold"
            >
              Additional Informations
            </MaterialTypography>
          </Stack>
          <Grid container spacing={2}>
            <Grid size={{ xs: 5, sm: 3, lg: 8 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  paddingLeft: "20px",
                  marginTop: "20px",
                }}
              >
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Notes{" "}
                  </MaterialTypography>
                </MaterialBox>
                <textarea
                  style={{
                    minHeight: "120px",
                    maxHeight: "120px",
                    maxWidth: "100%",
                    minWidth: "100%",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    padding: "15px",
                    marginTop: "10px",
                  }}
                  placeholder="Additional Informations"
                  // onChange={(e) => setAddition(e.target.value)}
                />
              </div>
            </Grid>
            <Grid size={{ xs: 5, sm: 3, lg: 4 }}>
              <MaterialBox lineHeight={0} display="inline-block">
                <MaterialTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Attachment{" "}
                </MaterialTypography>
              </MaterialBox>
            </Grid>
          </Grid>
        </CardContent>
        <MaterialBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <MaterialButton
            variant="gradient"
            color="dark"
            // onClick={handleDiscardButton}
          >
            Cancel
          </MaterialButton>
          <MaterialButton
            sx={{ m: 2 }}
            variant="gradient"
            color="dark"
            disabled={disabledHandel}
            // onClick={AddInvoice}
          >
            Submit
          </MaterialButton>
        </MaterialBox>
      </Card>
    </DashboardLayout>
  );
};

export default Payment;
