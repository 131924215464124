import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import ConfirmationDialog from "../../components/Dialog/ConfirmationDialog";
import {
  Autocomplete,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialBox from "../../components/MaterialBox";
import Grid from "@mui/material/Grid2";
import MaterialSelect from "../../components/MasterSelect";
import MaterialInput from "../../components/MaterialInput";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import { Margin } from "@mui/icons-material";
import request from "graphql-request";
import BASEURL from "../../pages/Utils/Baseurl";
import { trailBalance } from "../../Api/ApiQuery";

const Reports = () => {
  const [confirmationDialog, setConfirmationDialog] = useState({
    open: false,
    title: "",
    message: "",
    showCancel: true,
    showConfirm: true,
    customSection: null,
    onConfirm: () => {},
    onCancel: () => {},
    done: "",
    back: "",
  });
  const [date, setDate] = useState({
    fromDate: "yyyy-mm-dd",
    toDate: "yyyy-mm-dd",
    fromDateStatus: "unchecked",
    toDateStatus: "unchecked",
  });
  const [list, setList] = useState([]);
  const [debit, setDebit] = useState(0);
  const [credit, setCredit] = useState(0);
  const getList = () => {
    if (
      date?.fromDateStatus == "unchecked" ||
      date?.toDateStatus == "unchecked"
    ) {
      console.log("err");
    } else {
      const res = request(BASEURL, trailBalance, {
        from: date?.fromDate,
        to: date?.toDate,
      })
        .then((res) => {
          console.log(res);
          setList(res?.trailBalance);
          setCredit(res?.totalSummary?.totalCreditAll);
          setDebit(res?.totalSummary?.totalDebitAll);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    getList();
  }, [date]);
  const appointmentListTable = {
    columns: [
      {
        headerName: "Voucher Id",
        field: "voucherId",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Voucher Date",
        field: "voucherDate",
        name: "Date",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Ledger Name",
        field: "ledgerName",
        // name: "Time",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Debit Amount",
        field: "debit",
        // name: "Name",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Credit Amount",
        field: "credit",
        // name: "Phone Number",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
    ],
    rows:
      Array.isArray(list) &&
      list?.map((val, index) => ({
        id: index + 1,
        voucherId: val.voucherId,
        voucherDate: val.voucherDate,
        ledgerName: val.ledgerName,
        debit: val.debit,
        credit: val.credit,
      })),
  };

  const handleAppointmentData = (e) => {
    console.log(e);
    let temp = { ...date };
    temp = {
      ...temp,
      [e.target.name]: e.target.value,
      [e.target.name + "Status"]: "success",
    };
    console.log(temp);
    setDate(temp);
  };
  const toDate = new Date();
  return (
    <DashboardLayout>
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          message={confirmationDialog.message}
          showCancel={confirmationDialog.showCancel}
          showConfirm={confirmationDialog.showConfirm}
          customSection={confirmationDialog.customSection}
          onConfirm={confirmationDialog.onConfirm}
          onCancel={confirmationDialog.onCancel}
          done={confirmationDialog.done}
          back={confirmationDialog.back}
        />
      )}
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Reports Details
                </MaterialTypography>
              </Stack>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* <Grid> */}
                <div>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      From Date <span style={{ color: "red" }}>*</span>
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="fromDate"
                    type="date"
                    placeholder="ScheduledDate"
                    value={date?.fromDate}
                    onChange={handleAppointmentData}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                {/* {appointmentData.scheduledDateStatus === "error" && (
               <FormControl error>
                 <FormHelperText>
                   Please Enter a Valid Schedulated Date
                 </FormHelperText>
               </FormControl>
             )} */}
                {/* </Grid> */}
                <div style={{ marginLeft: "20px" }}>
                  <MaterialBox lineHeight={0} display="inline-block">
                    <MaterialTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      To Date <span style={{ color: "red" }}>*</span>
                    </MaterialTypography>
                  </MaterialBox>
                  <MaterialInput
                    name="toDate"
                    type="date"
                    placeholder="ScheduledDate"
                    value={date?.toDate}
                    onChange={handleAppointmentData}
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                    }}
                  />
                </div>
                {/* {appointmentData.scheduledDateStatus === "error" && (
               <FormControl error>
                 <FormHelperText>
                   Please Enter a Valid Schedulated Date
                 </FormHelperText>
               </FormControl>
             )} */}
              </div>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "80vh" }}>
          <MaterialBox>
            {/* <Grid container spacing={2}> */}
            {/* <Grid size={{ xs: 5, sm: 3,lg:3}}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Ledger Name <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="contactPerson"
                  // value={appointmentData.contactPerson}
                  // defaultValue={{ label: "Mr." }}
                  customStyles={{ optionPadding: 20 }}
                  // onChange={handleAppointmentData} //selectedValue, event
                  options={[].map((value) => {
                    return {
                      value: value.id,
                      label: value.firstName,
                    };
                  })}
                />
              </Grid> */}

            {/* <Grid size={{ xs: 5, sm: 3,lg:3}}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Ledger Name <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialSelect
                  name="contactPerson"
                  // value={appointmentData.contactPerson}
                  // defaultValue={{ label: "Mr." }}
                  customStyles={{ optionPadding: 20 }}
                  // onChange={handleAppointmentData} //selectedValue, event
                  options={[].map((value) => {
                    return {
                      value: value.id,
                      label: value.firstName,
                    };
                  })}
                />
              </Grid> */}

            {/* <Grid size={{ xs: 5, sm: 3,lg:3}}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Vochuer Name <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="name"
                  placeholder="Enter Your Name"
                  // value={appointmentData.name}
                  // onChange={handleAppointmentData}
                />
              </Grid> */}
            {/* <Grid size={{ xs: 5, sm: 3 ,lg:3}}>
                <MaterialBox lineHeight={0} display="inline-block">
                  <MaterialTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Vochuer Type <span style={{ color: "red" }}>*</span>
                  </MaterialTypography>
                </MaterialBox>
                <MaterialInput
                  name="name"
                  placeholder="Enter Your Name"
                  // value={appointmentData.name}
                  // onChange={handleAppointmentData}
                />
              </Grid> */}
            {/* </Grid> */}
            <div style={{ marginTop: "0px", position: "relative" }}>
              <MaterialDataGrid
                rows={appointmentListTable.rows}
                columns={appointmentListTable.columns}
                onRowClick={false}
              />
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  bottom: "10px",
                  right: "450px",
                  justifyContent: "space-between",
                }}
              >
                <p>
                  <span>Total Debit: </span>
                  <span style={{ fontWeight: "700" }}>{`₹${debit}`}</span>
                </p>
                <p>
                  <span style={{ paddingLeft: "55px" }}>Total Credit : </span>
                  <span style={{ fontWeight: "700" }}>{`₹${credit}`}</span>
                </p>
              </div>
            </div>
          </MaterialBox>
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default Reports;
