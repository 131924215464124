import request, { gql } from "graphql-request";

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      success
      errors
      token
      refreshToken
      user {
        id
        email
        username
        firstName
        lastName
        isActive
        role
      }
    }
  }
`;

export const createParentLedger = gql`
  mutation createParentLedger($input: CreateParentLedgerInput!) {
    createParentledger(input: $input) {
      set
    }
  }
`;

export const createChildLedger = gql`
  mutation createChildLedger($input: CreateChildLedgerInput!) {
    createChildledger(input: $input) {
      set
    }
  }
`;

export const createLedger = gql`
  mutation createledger($input: CreateLedgerInput!) {
    createLedger(input: $input) {
      set
    }
  }
`;
export const updateledger = gql`
  mutation updateledger($input: UpdateLedgerInput!) {
    updateLedger(input: $input) {
      set
    }
  }
`;
export const deleteledger = gql`
  mutation deleteledger($id: String!) {
    deleteLedger(id: $id) {
      set
    }
  }
`;

export const createinvoice = gql`
  mutation createinvoice($input: InvoiceInput!) {
    createInvoice(input: $input) {
      success
      message
    }
  }
`;
export const createaccounts = gql`
  mutation createaccounts($input: [CreateAccountsTableInput]!) {
    createAccountstable(input: $input) {
      set
    }
  }
`;

export const createreceipt = gql`
  mutation createreceipt($input: ReceiptVoucherInput!) {
    createReceiptVoucher(input: $input) {
      success
    }
  }
`;
