import React, { useState } from "react";
import DashboardLayout from "../../layoutcontainers/DashboardLayout";
import { Card, CardContent, CardHeader, Stack } from "@mui/material";
import MaterialTypography from "../../components/MaterialTypography";
import MaterialDataGrid from "../../components/MaterialDataGrid";
import MaterialButton from "../../components/MaterialButton";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
const PaymentList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const appointmentListTable = {
    columns: [
      {
        headerName: "Voucher Id",
        field: "scheduledDate",
        name: "Date",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        headerName: "Payment Date",
        field: "time",
        name: "Time",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Customer Name",
        field: "name",
        name: "Name",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Received Amount",
        field: "phoneNumber",
        name: "Phone Number",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Outstanding Amount",
        field: "status",
        name: "Status",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
      {
        headerName: "Action",
        field: "createdBy", // This is the field name you defined in your table columns
        name: "Created By",
        flex: 1,
        disableColumnMenu: true,
        editable: false,
      },
    ],
    rows: data.map((val, index) => ({
      sno: index + 1,
      id: val.id,
      scheduledDate: val.scheduledDate,
      time: val.time,
      name: val.name,
      phoneNumber: val.phoneNumber,
      status: val.status,
      createdBy: val.user.username,
    })),
  };
  const handleNewEmployee = () => {
    navigate("/Payment/Add-Payment");
  };
  return (
    <DashboardLayout>
      <Card>
        <CardHeader
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Stack direction="column" spacing={0}>
                <MaterialTypography variant="h5" fontWeight="medium">
                  Payment List
                </MaterialTypography>
              </Stack>
              <MaterialButton
                variant="gradient"
                color="dark"
                size="small"
                startIcon={<AddIcon />}
                onClick={() => handleNewEmployee()}
              >
                Add Payment
              </MaterialButton>
            </Stack>
          }
        />
        <CardContent sx={{ minHeight: "70vh" }}>
          <MaterialDataGrid
            rows={appointmentListTable.rows}
            columns={appointmentListTable.columns}
          />
        </CardContent>
      </Card>
    </DashboardLayout>
  );
};

export default PaymentList;
